import { ApplicationPage } from 'app/types';
import { Cont404 } from 'common/containers/404';
import { useRouterQuery } from 'common/router';
import { UnsubscribeFromMarketing } from 'domains/marketing/containers/unsubscribe-from-marketing';

const UnsubscribePage: ApplicationPage = () => {
  const query = useRouterQuery();
  const identity = query.sweed_email_id as string | undefined;

  if (!identity) {
    return <Cont404 />;
  }

  return <UnsubscribeFromMarketing identity={identity} />;
};

UnsubscribePage.getMainLayoutProps = () => ({
  size: 'md',
  centered: true,
  subheader: null,
});

export default UnsubscribePage;

import { Trans } from '@swe/shared/tools/intl/components/trans';
import Button from '@swe/shared/ui-kit/components/button';
import SectionHeading from '@swe/shared/ui-kit/components/section-heading';
import Stack from '@swe/shared/ui-kit/components/stack';
import UIText from '@swe/shared/ui-kit/components/text';
import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';

import { useCallback, useState } from 'react';

import styles from './styles.module.scss';

import NotificationUnsubscribeEndpoint from 'endpoints/profile/notifications/unsubscribe';

type UnsubscribeFromMarketingProps = {
  identity: string;
};

enum State {
  AwaitingAction = 'awaiting_action',
  UnsubscribedSuccessfully = 'unsub_success',
}

const Text = ({ children }: ComponentHasChildren) => (
  <UIText
    className={styles.text}
    size="lg"
  >
    {children}
  </UIText>
);

const UnsubscribeFromMarketing = ({ identity }: UnsubscribeFromMarketingProps) => {
  const [state, setState] = useState(State.AwaitingAction);

  const unsubscribe = useCallback(async () => {
    try {
      await NotificationUnsubscribeEndpoint.request({
        emailId: identity,
      });
      setState(State.UnsubscribedSuccessfully);
    } catch (e) {
      console.info(e);
    }
  }, [identity]);

  switch (state) {
    case State.AwaitingAction:
      return (
        <Stack spacing="md">
          <SectionHeading size="lg">Hold on!</SectionHeading>
          <Text>
            <Trans
              id="domains.profile.notifications.suspend.marketing.oneLeftWarningText"
              defaultMessage="You must have at least one marketing notification option enabled to participate in the loyalty program. Disabling all marketing notifications will remove you from the loyalty program, you will no longer be able to earn or use your points. Are you sure you want to disable all marketing notifications?"
            />
          </Text>

          <Button
            name="marketing-unsubscribe"
            className={styles.btn}
            size="lg"
            color="light"
            onClick={unsubscribe}
          >
            Unsubscribe
          </Button>
        </Stack>
      );
    case State.UnsubscribedSuccessfully:
      return (
        <Stack spacing="md">
          <SectionHeading size="lg">Done!</SectionHeading>
          <Text>You have successfully unsubscribed from marketing emails.</Text>
        </Stack>
      );
  }
};

export type { UnsubscribeFromMarketingProps };
export { UnsubscribeFromMarketing };

import createModernEndpoint, { APIErrorExtended } from '@swe/shared/network/endpoint-factories/modern';

type Params = {
  emailId: string;
};
type Data = undefined;
type Error = APIErrorExtended;

const NAME = '/Notifications/Unsubscribe';
const NotificationUnsubscribeEndpoint = createModernEndpoint<Params, Data, Error>(NAME);

export default NotificationUnsubscribeEndpoint;
